.projects-container {
    background-color: #fff;
    height: 100%;
}

.projects-body {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}

.projects-container-inner {
    width: 100%;
    justify-content: center;
    
}

.project-item {
    background-color: #d3d3d3;
    width: 80%;
    margin: auto;
    border-radius: 25px;
    padding-bottom: 5px;
}

.project-item-content {
    margin-left: 3%;
    margin-right: 3%;
    margin-bottom: 1%;
}

.project-item-content .techtag {
    align-self: flex-start;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    top: 5px;
    left: 5px;
    letter-spacing: 1px;
    padding: 5px;
    margin-left: 2%;
    float: left;
}

.project-item-content .orgtag {
    align-self: flex-start;
    margin-left: 5px;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    top: 5px;
    left: 5px;
    letter-spacing: 1px;
    padding: 5px;
    margin-left: 5px;
    float: left;
}

.project-item-content time {
    align-self: flex-start;
    margin-left: 5px;
    color: black;
    font-size: 12px;
    font-weight: bold;
    top: 5px;
    left: 5px;
    letter-spacing: 1px;
    padding: 5px;
    margin-right: 2%;
    float: right;
}

.RespHeader {
    text-align: left;
    margin-left: 4%;

}

.RespList ul li {
    padding-left: 0px;
    margin-left: 0px;
    text-align: left;
}
