video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}

.hero-container {
    background: url("./assets/NetworkBackground.jpg") center center/cover no-repeat;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    padding-bottom: 140px;
}

.hero-container > h1 {
    color: #fff;
    font-size: 100px;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000; 
}

.hero-container > h1:hover {
    
}

.hero-container > p {
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

}

.hero-btns {
    margin-top: 32px;

}

.hero-btns .btn {
    margin: 6px;
}

.hero-contnet-container {
    background-color: #fff;
    width: 40%;
    content: "";
    display: flex;
    border-radius: 25px;
}

.hero-contnet-container:hover {
    background-color: #fff;
    width: 40%;
    border-radius: 25px;
}

.hero-cc-header {
    width: 100%;
}

.hero-cc-body {
    display: flex;
}
 
.hero-cc-left {
    flex: 20%;
    align-items: left;
}

.hero-cc-right {
    flex: 60%;
    align-items: center;
    padding: 10px;
}

.headshot {
    object-fit: contain;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    width: auto;
    max-height: 100%;
    border-radius: 25px;
}

.mobileMainLine {
    visibility: hidden;
}

@media screen and (max-width: 960px) {
    .hero-container > h1 {
        font-size: 70px;
    }

    .hero-contnet-container {
        width: 60%;
    }

    .hero-contnet-container:hover {
        width: 60%;
    }

    .hero-cc-right > h2 {
        font-size: 30px;
    }

    .hero-cc-right > h2 {
        font-size: 25px;
    }

}

@media screen and (max-width: 768px) {
    .hero-container > h1 {
        font-size: 50px;
    }

    .hero-container {
        font-size: 30px;
    }

    .hero-contnet-container {
        width: 80%;
    }

    .hero-contnet-container:hover {
        width: 80%;
    }

    .btn-mobile {
        display: block;
        text-decoration: none;

    }

    .btn {
        width: 100%;
    }

    .hero-cc-right > h2 {
        font-size: 15px;
    }
}

@media screen and (max-width: 380px) {
    .hero-container > h1 {
        font-size: 30px;
    }

    .hero-container {
        font-size: 5px;
        padding-top: -100;
    }
    
    .btn-mobile {
        display: block;
        text-decoration: none;

    }

    .btn {
        width: 100%;
    }
    .hero-cc-left {
        width: 100%;
    }

    .hero-contnet-container {
        width: 70%;
    }

    .hero-contnet-container:hover {
        width: 70%;
    }

    .hero-cc-right {
        width: 0%;
        visibility: hidden;
    }

    .hero-cc-right > h2 {
        font-size: 5px;
        visibility: hidden;
    }

    .mobileMainLine {
        visibility: visible;
        background-color: #fff;
        width: 80%;
        border-radius: 25px;
        margin-top: 10px;
    }

    .mobileMainLine:hover {
        
    }

    .mobileMainLine > h2 {
        visibility: visible;
        font-size: large;
        padding-left: 2%;
        padding-right: 2%;
    }
  
    
}